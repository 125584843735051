.claimcard {
    background: var(--cardbg);
    padding: 15px;
}

.claimcard input {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    font-size: 12px;
}

.claimcard input:focus {
    background: var(--bgcolor);
    border: none;
    box-shadow: none;
    color: #fff;
}

.claimcard .inputsec input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-radius: 0px;
}

.claimcard .inputsec .inputspan {
    background: var(--bgcolor);
    border: none;
    color: var(--themecolor);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 2px solid var(--themecolor);
    font-size: 12px;
    height: 100%;
    padding: 10px;


}

.claimcard .inputsec .inputspan:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}

.claimcard .cardname {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;

}

.claimcard .btmsec .contrirow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.claimcard .btmsec .contrirow p {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.claimcard .btmsec .btnstyle {
    background: var(--labelbg);
    color: var(--whitetext);
    width: 100%;
    border-radius: 0px;
    font-size: 14px;
}

.claimcard .btmsec .btnstyle:hover {
    background: var(--themecolor);
}

.claimcard .claimimg {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50px;
}

.claimcard .swapsec {
    display: flex;
    align-items: center;
    gap: 10px;
}

.claimcard .swapsec .netname {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;

}

.claimcard .swapsec .netprice {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;

}

.claimcard .swaprow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.claimcard .toprow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.claimcard .toprow .btnstyle {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--blacktext);
    background: var(--themecolor);
    border-radius: 0px;
    width: 80px;

}

.claimcard .toprow .btnstyle:hover {
    background: var(--btnhover);
    color: var(--whitetext);
}
.claimcard .toprow .rightsec{
    display: flex;
    align-items: center;
    gap: 10px;
}