.footersec {
    background: var(--bgcolor);
    padding: 10px;
    padding-top: 30px;
}
/* .footersec .customcontainer{
    max-width: 1200px !important;
    width: 90%;
} */
.footersec .centersec .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justify;
    color: var(--whitetext);
    margin-bottom: 30px;

}
.footersec .leftsec .logoimg{
    width: 250px;
    margin-bottom: 20px;

}

.footersec .centersec .pageroute {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: justify;
    color: var(--lightgrey);
    text-decoration: none;
    margin-bottom: 10px;

}

.footersec .centersec .pageroute:hover {
    color: var(--themecolor);

}

.footersec .centersec .leftsec {
    text-align: center;
}

.footersec .centersec .routerow ul {
    list-style: none;
    /* padding-left: 0px; */
}

.footersec .centersec .routerow li {
    margin-bottom: 10px;
}
.footersec .rightsec{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footersec .rightsec .labelsec{
    background: var(--labelbg);
    padding: 5px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 120px;
    min-width: 120px;
    height: 45px;
}
.footersec .rightsec .labelsec .minilogo{
    width: 30px;
    height: 30px;
}
.footersec .rightsec .labelsec .labeltxt {
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    margin-bottom: 0px;
}
/* .footersec  .socialsec li{
    background: #2F3B52;
    border-radius: 50px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
} */
.footersec  .socialsec{
    list-style: none;
}
.footersec  .socialsec ul{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-left: 0px;
}
.footersec  .socialsec a{
  margin-left: 9px;
  }
.footersec  .socialsec  img{
  width:33px;
  height:33px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.dflex{
    display: flex;
}
.footersec  .socialsec a:hover .mediass {
  display: none;
}
.footersec  .socialsec a:hover .mediasshover{
    display: block;
}
.footersec  .socialsec a .mediasshover{
    display: none;
}

.footersec  .socialsec li:hover{
    background: var(--themecolor);
}
.footersec  .socialsec li svg{
  fill: #1C2029;
  width: 13px;
}
.footersec  .copytext{
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--whitetext);
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 50px;
}



@media screen and (min-width: 1250px) {
    .footersec .customcontainer{
        max-width: 1300px !important;
    }
}

@media screen  and (max-width:575px){
    .leftsecrowmargin
    {
        margin-top: 30px;
    }
    .row_marginminus
    {
        margin-left: -20px;
        margin-right: -20px;
    }
.footersec{
    padding-bottom: 80px;

}
.footersec .copytext {

    margin-top: 30px;
}
.footersec .leftsec .logoimg {
 
    margin-bottom: 50px;
}
.footersec .rightsec .labelsec{
    width: 100%;
    min-width: unset !important;
    max-width: unset !important;
    justify-content: start;
    padding-left: 10px;
}
.footersec .rightsec.rightsecmble{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

}
.footersec .socialsec ul {
    justify-content: center !important;
}
}
@media screen  and (max-width:991px){
    .dflex{
        display: flex;
        justify-content: center;
        margin-top: 12px;
    }
    .footersec .leftsec{
        text-align: left;
    }
    .footersec  .socialsec ul{
        justify-content: center;
        margin-top: 20px;
    }
    .footersec .centersec .routerow li, .footersec .centersec .routerow .title{
        text-align: left;
    }
    .footersec .centersec .routerow ul {
        padding-left: 0px;
        margin-bottom: 40px;
    }
    .footersec .rightsec {
        align-items: start;
    }
}
@media screen  and (min-width:577px) and (max-width:991px){
    .footersec .rightsec.rightsecmble{
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 20px;
    
    }
.footersec{
    padding-top: 60px;
}
.footersec .leftsec .logoimg{

    margin-bottom: 40px;

}
.footersec .copytext{
    margin-top: 25px;
}
.footersec .copysec{
    margin-bottom: 60px;
}
}
@media screen  and (min-width:992px){
.footersec .centersec .routerow ul{
    padding-left: revert-layer;
}
}
@media screen  and (min-width:1200px){
    .row_marginminus
    {
        margin-left: -20px;
        margin-right: -20px;
    }
.footersec .leftsec .logoimg{
    min-width: 165px;

}
}