.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 320px;
    margin: auto;
    border-radius: 0px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--themecolor);
    border-radius: 7px;
    color: var(--whitetext);
    font-size: 12px;
    border: 1px solid var(--themecolor);
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #fff;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}
.modalstyle .modalbody .label{
    font-size: 12px;
    color: var(--whitetext);
}
.modalstyle .modalbody .walletimg{
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.modalstyle  .walletrow{
    width: 100px;
    height: 100px;
    padding: 10px;
}
.modalstyle  .walletrow:hover .label{
    /* background: var(--themecolor); */
    color: var(--themecolor);

}
.modalstyle  .walletrow:hover {
opacity: 0.6;
cursor: pointer;
}
.modalstyle .modalfooter .footerdesc{
    color: var(--whitetext);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead{
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
}
.modalstyle .netbtn{
    background: var(--bgcolor);
    border: none;
    border-radius: 5px;
    color: var(--whitetext);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 5px 15px;
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    height: 48px;
}
.modalstyle .netbtn:hover{
    opacity: .65;

}
.modalstyle .modalbody ul{
    list-style:none;
    padding-left: 0px;
}
.modalstyle .netbtn .netimg{
  width: 30px;
  height: 30px;
}
@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
    {
        font-size: 14px;
    }
    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}

