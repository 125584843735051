.accordion_style .accordion_head button {
    background: var(--acc_clr);
    border: 1px solid var(--acc_clr) !important;
    box-shadow: none;
    filter: drop-shadow(rgba(25, 19, 38, 0.15) 0px 1px 4px);
    gap: 20px;
}

.accordion_style .accordion_item {
    background: #000;
    border: none;
}
.accordion_item .accordion_head .headrow{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
}

.accordion_item .accordion_head .headrow .imgsec{
    display: flex;
    gap: 30px;
    align-items: center;
}
.accordion_item .accordion_head .headrow .imgsec .leftimgsec{
    position: relative;
}
.accordion_item .accordion_head .headrow .imgsec .leftimgsec .fromimg {
    position: relative;
    z-index: 2;
    width: 25px;
    height: 25px;
    object-fit: contain;

}
.accordion_item .accordion_head .headrow .imgsec .leftimgsec .toimg {
    position: absolute;
    z-index: 0;
    right: -12px;
    z-index: 1;
    top: 8px;
    width: 25px;
    height: 25px;
    object-fit: contain;
}
.accordion_item .accordion_head .headrow .imgsec .coinname{
    font-size: 14px;
    font-weight: 900;
    color: var(--whitetext);
    margin-bottom: 0px;
    text-align: end;
}
.accordion_item .accordion_head .headrow  .listsec .labelname{
    color: var(--whitetext);
    font-size: 12px;
    margin-bottom: 5px;
}
.accordion_item .accordion_head .headrow  .listsec .labelvalue{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
}
.accordion_item .accordion_head .headrow  .listsec .calculatorbtn:hover{
    opacity: 0.65;
    cursor: pointer;
    position: relative;

}
.accordion_item .accordion_head .headrow  .listsec.liqsec{
    display: flex;
    align-items: flex-start;
    gap: 40px;
    justify-content: space-between;
}
.accordion_item .accordion_head .headrow  .listsec.multisec .multitooltip{
    display: flex;
    align-items: end;
    gap: 20px;
}
.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
}

.accordion_style .accordion_head button::after{
filter: invert(1);
background-size: 15px;
}
.accordion_style .accordion_body .corelabel{
    background: var(--themecolor);
    border: 2px solid var(--whitetext);
    border-radius: 10px;
    color: var(--whitetext);
    padding: 2px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap:5px;
    margin-bottom: 0px;
    width: 80px;
    margin-top: 10px;
}

.accordion_style .accordion_body   .contractlink{
    color: var(--whitetext);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}
.accordion_style .accordion_body  .contractlink:hover{
    color: var(--themecolor);
}

.accordion_style .accordion_body   .harvestrow{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.accordion_style .accordion_body  .harvestrow .label{
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    margin-bottom: 0px;
}
.accordion_style .accordion_body  .harvestrow .harvestbtn{
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    width: 120px;
}
.accordion_style .accordion_body .harvestrow .harvestbtn:hover{
    background: var(--btnhover) ;
    border: 1px solid var(--btnhover) ;
    color: rgb(255, 255, 255) ;
    transition: none 0s ease 0s ;
}
.accordion_style .accordion_body  .harvestrow{
    margin-bottom: 10px;
    align-items: center;
}
.accordion_style .accordion_body .bordecard .cardlabel{
    color: var(--whitetext);
    font-size: 14px;
    margin-bottom: 5px;

}
.accordion_style .accordion_body .bordecard{
    border: 2px solid var(--acc_clr);
    border-radius: 20px;
    padding: 15px;
    width: 85%;
    height: 100%;
    margin: auto;
}


.accordion_style .accordion_body .walletbtn{
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    width: 100%;
}
.accordion_style .accordion_body .enablebtn{
    background: transparent !important;
    border: 2px solid var(--acc_clr) !important;

}
.accordion_style .accordion_body .walletbtn:hover {
    background: var(--btnhover) ;
    border: 1px solid var(--btnhover) ;
    color: rgb(255, 255, 255) ;
    transition: none 0s ease 0s ;

}
.accordion_style .accordion_body .enablebtn:hover{
    background: var(--acc_clr) !important;

}
 .detailist .labelname{
    font-size: 16px;
    color:var(--greytext); 
    margin-top: 8px;
    margin-bottom: 0px;
    font-weight: 700;
}
.accordion_style .accordion_body .mblelist{
    display: flex;
    justify-content: space-between;
}
.accordion_style .accordion_body .mblelist .labelname ,.accordion_style .accordion_body .mblelist .labelvalue {
    color: var(--whitetext);
    font-size: 14px;
    margin-bottom: 0px;

}
.accordion_style .accordion_body .mblelist .labelvalue {
    display: flex;
    align-items: center;
}
@media screen and (max-width:575px) {
    .accordion_item .accordion_head .headrow .imgsec
    {
        align-items: flex-start !important;
    }
    .accordion_item .accordion_head .headrow{
        display: grid;
        gap: 10px;
        grid-template-columns: auto auto;
    }
    
    
}
.accordion_style .accordion_body 
{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

@media screen and (min-width:992px)
{
    .accordion_item .accordion_head .headrow.headrowgrid{
        display: grid;
           
            grid-template-columns: 19% 19% 19% 19% 19%;
        }
        
    .rowmarg
    {
        margin-left: 2.5%;
    }
}



@media screen and (min-width:768px) and (max-width:991px)
{
    .accordion_item .accordion_head .headrow.headrowgrid{
        display: grid;
           
            grid-template-columns: 30% 25% 25% 15%;
        }
        
   
}

@media screen and (max-width:767px)
{
    .accordion_item .accordion_head .headrow.headrowgrid{
        display: grid;
           
            grid-template-columns: 60% 40%;
            grid-column-gap: 20px;
            gap:20px;
        }
        
   
}



.accordion_style .accordion_body  .harvestrow .labelsm{
    font-weight: 600;
    font-size: 12px;
    color: var(--whitetext);
    margin-bottom: 0px;
}


.btnicon
{
    background-color: #1c2029;
    color:#fff;
    border-radius: 0px;
    padding: 8px 13px;
    font-size: 12px;
    font-weight: 600;
}



.btnicon:hover,.btnicon:focus
{
    background: rgba(254, 128, 201, 0.15);
    color:#fff;
}

@media screen and (max-width:767px)
{
    .harvestrowcol
    {
        flex-direction: column;
        align-items: flex-start !important;
    }
}

.labelvaluebrak
{
    word-break: break-all;
    white-space: break-spaces;
}

.declabel{
    font-size: 14px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
    text-decoration: none;
    background: transparent;
    border: none;
}
.declabel:hover{
    color: var(--whitetext);

}
.wordbreakval
{
    word-break: break-all;
}
.brakvaltext
{
    word-break: break-all;
}