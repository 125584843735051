.innerpages {
    padding-top: 50px;
    padding-bottom: 70px;
    min-height: 70vh;
}
/* .innerpages [class="container"]{
    max-width: 1200px;
} */
.innerpages .headrow .pagehead {
    font-size: 23px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.innerpages .headrow .pagedesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
}
.innerpages .filterrow .leftfiltersec{
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
        

    
}
.innerpages .filterrow{
    align-items: center;
}
.innerpages .filterrow .leftfiltersec .liststyle{
    display: flex;
    gap: 15px;
    align-items: center;
}
.innerpages .filterrow .leftfiltersec .liststyle svg{
    cursor: pointer;

}
.innerpages .filterrow .leftfiltersec .liststyle svg:hover{
    fill: var(--themecolor);

}
.innerpages .filterrow .leftfiltersec .togglesec {
    display: flex;
    align-items: center;
    gap: 10px;
}
.innerpages .filterrow .leftfiltersec .togglesec .spanlabel{
    color: var(--whitetext);
}
.innerpages  .filterrow  .rightfiltersec{
    display: grid;
    grid-template-columns:20% 78%;
    gap: 20px;

}
.innerpages .filterrow .leftfiltersec .tabsec{
    background: var(--bgcolor);
    display: flex;
    border-radius: 12px;
}
.innerpages .filterrow .leftfiltersec .tabsec p{
    color: var(--whitetext);
    font-size: 15px;
    font-weight: 700;
    padding: 5px 20px;
    margin-bottom: 0px;
    cursor: pointer;
}
.innerpages .filterrow .leftfiltersec .tabsec p.active{
    background: var(--whitetext);
    color: var(--blacktext);
    border-radius: 12px;
    
}
.innerpages .filterrow .leftfiltersec .tabsec p:hover{
    color: var(--themecolor);
    opacity: 0.65;
}
.innerpages .dropdownstyle button {
    background: var(--bgcolor);
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerpages .dropdownstyle button:active {
    background: var(--bgcolor);
    border: none;
}

.innerpages .dropdownstyle .dropdownmenustyle {
    background: var(--bgcolor);
    border: none;
    width: 100%;
    color: var(--whitetext);
}

.innerpages .dropdownstyle .dropdownmenustyle a {
    color: var(--whitetext);
}

.innerpages .dropdownstyle .dropdownmenustyle a:hover {
    color: var(--themecolor);
    background: none;
}
.innerpages .rightfiltersec .labelname{
    color: var(--themecolor);
    font-size: 14px;
}
.innerpages .rightfiltersec .inputstyle{
    background:var(--bgcolor);
    box-shadow: none;
    border: none;
    color: var(--whitetext);
}
.innerpages .rightfiltersec .inputstyle::placeholder{
    color: var(--whitetext);
}
.innerpages .rightfiltersec .inputstyle:focus{
    box-shadow: none;
}
.innerpages .farmcontentsec .emptylabels{
    font-size: 18px;
    color: var(--themecolor);
    text-align: center;
}
.innerpages .poolscardsec{
    display: flex;
    align-items: center;
    justify-content: center;
}
.innerpages  .finshedlabel{
    color: var(--whitetext);
    text-align: center;
    margin-top: 30px;
}
@media screen and (max-width:575px) {
    .innerpages  .filterrow  .rightfiltersec
    {
        grid-template-columns: 100%;
    }
    .innerpages .filterrow .leftfiltersec{
        justify-content: center;

    }
}

@media screen and (min-width:576px) and (max-width:767px)
{
    .innerpages  .filterrow  .rightfiltersec
    {
        grid-template-columns: 35% 63%;
    }
}



.innerpages .balancecard {
    background: var(--bgcolor);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.innerpages .balancecard .labelname {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--lighttext);
    margin-bottom: 5px;
}

.innerpages .balancecard .labelvalue {
    font-size: 14px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;
}

.innerpages .balancecard .balanceimg {
    width: 25px;

}

.connect_btn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 5px 20px;
    border-radius: 11px;
    max-width: 200px;
    height: 50px;
    /* width: 100%; */
}

.connect_btn:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}