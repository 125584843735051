.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 420px;
    margin: auto;
    border-radius: 0px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}
.modalstyle .btnstyle {
    background: var(--themecolor);
    border-radius: 7px;
    color: var(--whitetext);
    font-size: 12px;
    border: 1px solid var(--themecolor);
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #fff;
}
.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}

.modalstyle
{
    padding-right: 8px !important;
}

.modalstyle .coinimgstyle{
    width: 15px;
    height: 15px;
    border-radius: 50px;
    object-fit: contain;
}
.connectwalletbtn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 48px;
}
.connectwalletbtn:hover
{
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}
.tooltip_button
{
    background-color: transparent !important;
    border:none;
    border-radius: 0px;
    color:#fff;
    padding: 0px;
    margin-left: 10px;
    margin-top: -8px;
}

.tooltip_style
{
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}
.swapsec {
    margin-bottom: 20px;
}
.swapsec .rowslist{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.swapsec .rowslist .coinimg{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.swapsec .rowslist p{
    color: var(--whitetext);
    margin-bottom: 0px;
    font-size: 14px;
}
.desccontent{
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
}
.listcard {
    background: var(--bgcolor);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px;

}
.listcard ul{
    padding-left: 0px;
    list-style: none;
}
.listcard ul li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}
.listcard ul li p,.coinvaluespan{
    color: var(--whitetext);
    margin-bottom: 0px;
    font-size: 12px;
}
@media screen and (max-width:400px) {

    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}
.coinimgstyle
{
    width: 20px;
    height: 20px;
    object-fit: contain;
}


@media only screen and (max-width:575px)
{
    .listcard ul li,.swapsec .rowslist
    {
        flex-direction: column;
    }
    .swapsec .rowslist
    {
        align-items: flex-start;
    }
}