.roundcard {
    background: var(--bgcolor);
    padding: 15px;
    display: flex;
    justify-content: space-between;
}
.roundcard:hover{
    box-shadow: 0 2px 8px 0 var(--themecolor);
    cursor: pointer;
}
.roundcard .btnside .btnstyle {
    background: var(--cardbg);
    color: var(--whitetext);
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.47999998927116394px;
    text-align: center;
    border-radius: 0px;
}

.roundcard .btnside .btnstyle:hover {
    background: var(--themecolor);
    color: var(--whitetext);
}

.roundcard .btnside .btnstyle svg {
    color: var(--whitetext);
}

.roundcard .tablename {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;

}

.roundcard .typename {

    font-size: 9px;
    font-weight: 300;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
    margin-bottom: 5px;


}

.roundcard .value {
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;
}

.roundcard .value span {
    font-size: 10px;
    font-weight: 300;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--lighttext);
    margin-bottom: 0px;
}

.roundcard .iconimg {
    width: 15px;
    height: 15px;
    border-radius: 50px;
}

.roundcard .valuerow {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: row;
}

.roundcard .valuemainrow {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.roundcard .pfimg {
    width: 45px;
    height: 45px;
    border-radius: 50px;
    object-fit: cover;
}

.roundcard .pfsec {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-left: 20px;
}

.roundcard .tabletype {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.roundcard .btnside {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    gap: 10px;
}

.roundcard .userlistsec {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.roundcard .joinuserimg {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    object-fit: cover;

}

@media screen and (max-width:991px) {
    .roundcard .pfsec{
        padding-left: 0px;
        flex-direction: column;
        align-items: start;
    }
   
}